<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/imgs/neo-logo.png" width="150">    
    <br>
    <h3>NeoNetworks - Portal</h3>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    // HelloWorld
  }
}
</script>
