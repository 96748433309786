import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/styles.css'
import 'bootstrap/dist/css/bootstrap.css'

const app = createApp(App)
app.use(router)
router.isReady().then(() => app.mount('#app'))

